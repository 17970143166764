import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "扩散系数", content: "高" },
  {
    color: "bg-case_orange",
    title: "抽签法",
    content: "提示画",
  },
  { color: "bg-accent", title: "如何检查结果", content: "DM" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "我希望我的推文能够传播" },
  { title: "为了发送优惠券代码并避免发送纸质媒体的麻烦" },
  { title: "想分发自己的优惠券代码以促进销售" },
  { title: "希望增加其实体店和电子商务网站的访问量" },
]

const InstantWinCouponCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="推特活动案例研究和优惠券发放活动"
          pagepath={location.pathname}
          pagedesc="该活动旨在通过向参与者分发优惠券，鼓励他们去商店购物。"
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                优惠券
                <br className="block md:hidden" />
                发放活动
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                该活动旨在通过向参与者分发优惠券，鼓励他们去商店购物。
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <StaticImage
                  layout="fullWidth"
                  alt="优惠券发放活动"
                  src="../../../../images/case_study/mv_instant-win-coupon-campaign.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                推荐使用
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                运动的特点
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      参与者
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">缓解</p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">激励</p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      联络人
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">缓解</p>
                      <StarRates rate={4} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  该活动旨在通过向参与者分发优惠券，鼓励他们去商店购物。
                  <br />
                  <br />
                  参与要求是关注和转发。
                  它很容易参与，而且具有高度的扩散性，即时赢的形式可以激励参与者。
                  <br />
                  <br />
                  如果你的账户有一个实体店或电子商务网站，无论你有多少粉丝，都建议开展这项活动。
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                实施PARKLoT的
                <br className="block md:hidden" />
                运动工具会如何改变现状？
              </h2>
              <p className="mb-6 text-text">
                运营成本可以大大降低：引入PARKLoT可以简化
                "确定获奖者和沟通获奖者的流程"。
                <br />
                此外，它还可以积累数据，用于未来的活动规划。
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    如果活动是手动进行的
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      通过Twitter打开相关活动页面
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      查看参与者的名单
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          检查用户详情屏幕
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          获奖者的确定
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        有多少个赢家就重复多少次
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        搜索获奖者的账户
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        DM的创建、确认和传输
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        有多少个赢家就重复多少次
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-text mb-3">
                      仅仅完成运动就
                      <br className="block md:hidden" />
                      <span className="font-bold text-2xl text-accent">
                        需要3-5小时
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        需要登录Twitter，
                        <span className="font-bold">
                          逐一查看每个参与者的资料
                        </span>
                      </li>
                      <li>
                        需要
                        <span className="font-bold">逐一给获奖者发送DM</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      当使用PARKLoT时
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        提供给PARKLoT的运动信息，由我们登记的数据
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        活动自动开始，并自动吸引转发的用户
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        当获奖者被选中时，会自动发送带有优惠券URL的DM
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        活动实施过程只需
                        <br className="md:hidden block" />
                        <span className="text-2xl text-accent leading-normal">
                          20-30分钟!
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          <span className="font-bold">
                            查看CSV格式的分类和过滤后
                          </span>
                          的参与者名单
                        </li>
                        <li>
                          <span className="font-bold">
                            抽出赢家和输家的过程可以完全自动化。
                          </span>
                        </li>
                        <li>
                          <span className="font-bold">
                            减少发送优惠券的麻烦和印刷成本。
                          </span>
                        </li>
                        <li>
                          （作为次要效果）
                          <span className="font-bold">
                            可以对申请人的趋势进行分析，并用于未来的活动。
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      在以下情况下
                      <br className="block md:hidden" />
                      不建议使用工具
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>不需要申请人的信息</li>
                        <li>更少的赢家</li>
                        <li>没有实施工具的费用</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                使用PARKLoT时的
                <br className="block md:hidden" />
                具体运动程序
              </h2>
              <FlowNumber
                num="01"
                parklot={false}
                title="请向PARKLoT提供你的活动信息"
              >
                <p className="text-text">
                  请在活动开始前至少三个工作日通过电子邮件或Slack与我们联系，提供活动信息。
                  <br />
                  所需数据可在
                  <a
                    className="text-accent underline"
                    href="https://www.notion.so/xhackttl/Ver-2-64d0b7b19a0548d09ec5323d1f9ce155"
                    target="_blank"
                    rel="noreferrer"
                  >
                    [优惠券发放活动]时间表和提交数据第2版
                  </a>
                  中找到。
                </p>
              </FlowNumber>

              <FlowNumber num="02" parklot={true} title="登记参加该活动">
                <p className="text-text">我们将根据你提供的信息来设置数据</p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="活动推文自动发布，活动开始。"
              >
                <p className="text-text">
                  在开始时间，推文会自动发布，活动开始。
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                last={true}
                title="DM会自动发送给参与者"
              >
                <p className="text-text">
                  转发用户被收集起来，并计算出获胜的概率，以自动确定获胜者。
                  可以显示优惠券的URL会通过DM自动发送给获奖者。
                  <br />
                  活动参与者的名单可以从管理屏幕上查看。
                </p>
              </FlowNumber>
            </div>
          </section>
          {/* クーポンの種類は？ */}
          <section className="coupon bg-zinc-50">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                有哪些类型的优惠券？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                有三种类型的优惠券可供选择：可取消的、有时间限制的和在一定时间内无限制的。
              </p>
              <div className="p-4 text-center bg-white">
                <StaticImage
                  layout="constrained"
                  alt="即时赢取运动的实施成本"
                  src="../../../../images/case_study/coupon-type.png"
                  objectfit="contain"
                />
              </div>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoT的月费
                <br className="block md:hidden" />
                中包括哪些功能？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                在月费范围内可以进行优惠券发放活动。
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                月费中包含的功能
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 md:justify-around">
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="自动抽奖"
                    src="../../../../images/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">自动抽奖</h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt=" 参与者信息的CSV输出"
                    src="../../../../images/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    参与者信息的CSV输出
                  </h4>
                  <p className="text-text text-xs">
                    *如果需要进行复杂的定制，
                    <br />
                    可能会收取额外费用
                  </p>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="自动将竞赛结果的URL以DM方式传送给参赛者。"
                    src="../../../../images/case_study/parklot_function03.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    自动将结果URL或优惠券URL
                    <br />
                    的DM传输给参与者
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default InstantWinCouponCampaign
